@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Anonymous Pro', monospace, source-code-pro, Menlo, Monaco,
    Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
}
